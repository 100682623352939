import { useRef, useEffect, useCallback } from "react";
import {
  sendDataToFireBase,
  IFirebaseConfig,
} from "@push-notifications/firebase";

import { detect } from "detect-browser";
import { getTranslate } from "@push-notifications/translate";

const firebaseProps: IFirebaseConfig = {
  firebaseConfig: {
    apiKey: process.env.REACT_APP_API_KEY ?? "",
    authDomain: process.env.REACT_APP_AUTH_DOMAIN ?? "",
    projectId: process.env.REACT_APP_PROJECT_ID ?? "",
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET ?? "",
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID ?? "",
    appId: process.env.REACT_APP_APP_ID ?? "",
  },
  validKey: process.env.REACT_APP_PUBLIC_VAPID_KEY ?? "",
  baseURL: process.env.REACT_APP_BASE_URL ?? "/",
};

export const usePresenter = () => {
  const browserName = detect()?.name;
  const effectRan = useRef(false);
  const translateData = getTranslate();

  useEffect(() => {
    if (
      !effectRan.current &&
      (browserName === "chrome" || browserName === "opera")
    ) {
      sendDataToFireBase(firebaseProps);
    }

    return () => {
      effectRan.current = true;
    };
  }, [browserName]);

  useEffect(() => {
    document.title = `${process.env.REACT_APP_PROJECT_NAME} | ${translateData.title}`;
  }, [translateData.title]);

  const pushClick = useCallback(async () => {
    sendDataToFireBase(firebaseProps);
  }, [browserName]);

  return {
    pushClick,
    browserName,
    allowText:
      translateData.allowText ??
      'Please click the "Allow" button to receive notifications about new bonuses, promotions, and tournaments.',
  };
};
