import axios from "axios";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getToken, getMessaging, isSupported } from "firebase/messaging";
import Cookies from "universal-cookie";
import * as Sentry from "@sentry/browser";

export interface IFirebaseConfig {
  firebaseConfig: {
    apiKey: string;
    authDomain: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
  };
  validKey: string;
  baseURL: string;
}

export const sendDataToFireBase = (props: IFirebaseConfig) => {
  const cookies = new Cookies();
  const getExpiresDay = (days: number): Date => {
    return new Date(Date.now() + days * 24 * 60 * 60 * 1000);
  };

  const setCookies = (name: string, value: string, days: number) => {
    cookies.set(name, value, {
      path: "/",
      expires: getExpiresDay(days),
      sameSite: 'none',
      secure: true
    });
  }

  setCookies("saved_token", '', 1);
  setCookies("subscription_time", '', 1);
  setCookies("user_uuid", '', 1);
  setCookies("last_validation", '', 1);

  const queryParameters = new URLSearchParams(window.location.search);

  const userUuid = queryParameters.get("user_uuid") ?? cookies.get("user_uuid");
  setCookies("user_uuid", userUuid, 365);

  const jwtToken = queryParameters.get("token") ?? "";
  const goto = queryParameters.get("goto") ?? "";
  const backUrl = goto ? decodeURIComponent(goto) : null;

  const saveToken = async (
    userUuid: string,
    token: string,
    jwtToken: string,
  ) => {
    axios
      .post(
        "/api/v1/subscribe/web",
        {
          platform: "web",
          firebase_token: token,
          user_uuid: userUuid,
        },
        {
          baseURL: props.baseURL,
          headers: { Authorization: "Bearer " + jwtToken },
        },
      )
      .then(() => {
        return setCookies("saved_token", token, 365);
      })
      .catch((error: any) => {
        console.log('/api/v1/subscribe/web error', error);
      });
  };

  const getFirebaseToken = () =>
    getOrRegisterServiceWorker().then((serviceWorkerRegistration) => {
      const firebaseApp = initializeApp(props.firebaseConfig);
      const messaging = getMessaging(firebaseApp);
      const analytics = getAnalytics(firebaseApp);

      return getToken(messaging, {
        vapidKey: props.validKey,
        serviceWorkerRegistration,
      });
    });

  const sendDataMetrics = () => {
    try {
      // @ts-ignore
      if (typeof send_data == "function") {
        // @ts-ignore
        send_data({ event: "Push Subscribed" });
      }
    } catch (e) {
      console.log("send_data", e);
    }

    setTimeout(() => {
      complete(backUrl);
    }, 3500);
  }
  const handleGetFirebaseToken = () => {
    getFirebaseToken()
      .then((firebaseToken) => {
        if (firebaseToken) {
          setFirebaseToken(firebaseToken);

          if (firebaseToken !== cookies.get("saved_token")) {
            saveToken(userUuid, firebaseToken, jwtToken).then(() => {
              sendDataMetrics();
            });
          } else {
            sendDataMetrics();
          }
        } else {
          complete(backUrl);
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving firebase token. ", err);
        complete(backUrl);
      });
  };
  const setFirebaseToken = (firebaseToken: string) => {
    const currentDate = new Date().toUTCString();
    setCookies("last_validation", currentDate,1)

    if (firebaseToken !== cookies.get("saved_token")) {
      setCookies("current_token", firebaseToken, 365);

      if (firebaseToken !== cookies.get("current_token")) {
        setCookies("subscription_time", currentDate, 365);
      }
    }
  };

  const getOrRegisterServiceWorker = () => {
    if ("serviceWorker" in navigator) {
      return window.navigator.serviceWorker
        .getRegistration("/firebase-push-notification-scope")
        .then((serviceWorker) => {
          if (serviceWorker) return serviceWorker;
          return window.navigator.serviceWorker.register(
            "/firebase-messaging-sw.js",
            {
              scope: "/firebase-push-notification-scope",
            },
          );
        });
    }
    throw new Error("The browser does`t support service worker.");
  };

  const complete = (url: string | null) => {
    isSupported().then((result) => {
      if (result) {
        return window.close();
      }
    })

    if (url !== null) {
      return window.location.replace(url);
    }
  };

  const sentryInit = async () => {
    await Sentry.init({
      dsn: "https://53ec51ad376c4a64bfd361d83951419c@sentry.firmare.cc/12",
      integrations: [new Sentry.BrowserTracing()],
      tracesSampleRate: 1.0,
    });
  };

  sentryInit().catch();

  isSupported().then((result) => {
    if (result) {
      Notification.requestPermission()
        .then((permission) => {
          console.log('permission', permission);
          if (permission === 'granted') {
            handleGetFirebaseToken();
          }
        })
        .catch((err) => {
          console.log("Unable to get permission to notify.", err);
          complete(null);
        });
    } else {
      console.log("isSupported", result);
    }
  });
};
